<template>
  <div class="reports-page-datatable">
    <el-row :gutter="10">
      <el-col :span="3">
        <p style="font-size: 10.5px; font-style: italic;">Exibindo {{ dataFiltered.length }} registros</p>
      </el-col>
      <el-col :span="14">
        <el-input
          v-model="searchBox"
          size="mini"
          placeholder="Filtrar pelo número do documento, número da carga, ou nome do cliente"
        />
      </el-col>

      <el-col :span="4">
        <el-tooltip
          class="box-item"
          effect="dark"
          content="Ao exportar todos os campos são sempre enviados"
          placement="top-start"
        >
        <el-switch
          v-model="showAllColumns"
          active-text="Exibir todas as colunas"
          size="small"
        >
        </el-switch>
      </el-tooltip>
      </el-col>
    </el-row>
    <el-table :data="dataFiltered" style="width: 100%; font-size: 11px; padding: 0;" :max-height="screenHeight" class="el-table--mini">
      <el-table-column sortable prop="customer" label="Cliente" width="300" />
      <el-table-column sortable prop="cargo_id" label="Carga" width="110">
        <template #default="scope">
          <el-link :href="`${baseurl}/cargos/${scope.row.branch_id}/${scope.row.cargo_id}`" target="_blank" type="primary">{{ scope.row.cargo_id }}</el-link>
        </template>
      </el-table-column>
      <el-table-column sortable prop="cargo_status" label="Status" width="110" />
      <el-table-column v-if="showAllColumns" sortable prop="order_number" label="Pedido" width="100" />
      <el-table-column v-if="showAllColumns" sortable prop="branch_name" label="Filial" width="200" />
      <el-table-column sortable prop="invoice" label="Documento" width="110" />
      <el-table-column sortable prop="invoice_ammount" label="Valor" width="110" />
      <el-table-column sortable prop="invoice_status" label="Status" width="100" />
      <el-table-column v-if="showAllColumns" sortable prop="invoice_items" label="Itens" width="100" />
      <el-table-column sortable prop="payments" label="Pagamentos previsto" width="250" />
      <el-table-column sortable prop="paymentsEdited" label="Editado" width="90" />
      <el-table-column sortable prop="paymentsEditedTo" label="Pagamento editado para" width="200" />
      <el-table-column sortable prop="nsu" label="NSU" width="150" />
      <el-table-column v-if="showAllColumns" sortable prop="truck_id" label="Veiculo" width="100" />
      <el-table-column v-if="showAllColumns" sortable prop="driver_name" label="Motorista" width="200" />
      <el-table-column v-if="showAllColumns" sortable prop="cargo_started_at" label="Iniciada em" width="200" />
      <el-table-column v-if="showAllColumns" sortable prop="cargo_finished_at" label="Finalizada em" width="200" />
      <el-table-column v-if="showAllColumns" sortable prop="checked_log" label="Conf log" width="90" />
      <el-table-column v-if="showAllColumns" sortable prop="checked_log_by" label="Conferido por" width="200" />
      <el-table-column v-if="showAllColumns" sortable prop="checked_log_at" label="Conferido data" width="200" />
      <el-table-column v-if="showAllColumns" sortable prop="checked_fin" label="Conf fin" width="100" />
      <el-table-column v-if="showAllColumns" sortable prop="checked_fin_by" label="Conferido por" width="200" />
      <el-table-column v-if="showAllColumns" sortable prop="checked_fin_at" label="Conferido data" width="200" />
    </el-table>
  </div>
</template>

<script>
import exportJson from '@/helpers/exportXLSX'

export default {
  name: 'report-table-cargos-fin',
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    fields: {
      type: Object,
      default: () => {
        return {
          cliente: 'customer',
          pedido: 'order_number',
          documento: 'invoice',
          valor_total: 'invoice_ammount',
          total_itens: 'invoice_items',
          status_documento: 'invoice_status',
          veiculo: 'truck_id',
          motorista: 'driver_name',
          conferente: 'checker_name',
          filial: 'branch_name',
          incio: 'cargo_started_at',
          fim: 'cargo_finished_at',
          carga: 'cargo_id',
          carga_status: 'cargo_status',
          conferido_logistica: 'checked_log',
          conferido_logistica_por: 'checked_log_by',
          conferido_logistica_data: 'checked_log_at',
          conferido_financeiro: 'checked_fin',
          conferido_financeiro_por: 'checked_fin_by',
          conferido_financeiro_data: 'checked_fin_at',
          pagamento_previsto: 'payments',
          pagamento_editado: 'paymentsEdited',
          pagamento_editado_para: 'paymentsEditedTo',
          numeros_nsu: 'nsu',
        }
      },
    },
  },
  data() {
    return {
      showAllColumns: false,
      searchBox: '',
      baseurl: window.location.origin
    }
  },
  computed: {
    dataFiltered() {
      return this.data.filter((d) => {
        return (
          !this.searchBox || this.searchBox
            .toLowerCase()
            .split(' ')
            .every((v) =>
              `${d.customer}${d.cargo_id}${d.invoice}`
                .toLowerCase()
                .includes(v)
            )
        )
      })
    },
    screenHeight() {
      return (window.innerHeight -260)
    }
  },
  watch: {},
  mounted() {
    this.$emit('loaded')
  },
  methods: {
    DownloadXLS() {
      exportJson.DownloadXLS(this.dataFiltered, this.fields)
    },
    DownloadCSV() {
      exportJson.DownloadCSV(this.dataFiltered, this.fields)
    },
  },
}
</script>
